import React, {useEffect, useState} from 'react';
import QbeLogo from "../assets/QBE-logo-on-light.png";
import {makeStyles} from "@material-ui/core/styles";
import {connect, useSelector} from "react-redux";
import {
    _getLang,
    _hideModal,
    _hideSpinner, _setCpToken,
    _setLang,
    _showModal,
    _showSpinner
} from "../store/system/service";
import useConfig from "../components/qnect-ui/qnect-config";
import styled, {css} from "styled-components";
import QnectTextField from "../components/qnect-ui/qnect-text-field";
import {lgDown} from "../ui/common";
import QnectButton from "../components/qnect-ui/qnect-button";
import {_decryptResetPasswordToken, _resetPassword} from "../store/user/service";
import {StyledModalDiv} from "../components/login-style";
import * as TagManager from "react-gtm-module";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as HideIcon} from "../assets/registration/icon_hide.svg";
import {ReactComponent as ShowIcon} from "../assets/registration/icon_show.svg";
import zxcvbn from "zxcvbn";
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
    wrapper: {
        width: "100%",
        background: "rgb(246,248,250)",
        minHeight: "calc(100vh - 64px)",
        [theme.breakpoints.down("sm")]: {
            minHeight: "calc(100vh + 28px)",
        },
        [theme.breakpoints.up("lg")]: {}

    },
    topBg: {
        width: "100%",
        backgroundColor: "#FFFFFF",
        [theme.breakpoints.down("sm")]: {},
        [theme.breakpoints.up("lg")]: {
            height: "432px"
        }
    },
    topRow: {
        margin: "0 auto",
        width: "100%",
        maxWidth: "1440px",
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            height: "44px",
            padding: "0 12px",
            alignItems: "center",
            borderBottom: "1px solid #E1E4E8"
        },
        [theme.breakpoints.up("lg")]: {
            alignItems: "flex-end",
            height: "75px"
        }
    },
    logo: {
        [theme.breakpoints.down("sm")]: {
            width: "70px"
        },
        [theme.breakpoints.up("lg")]: {
            width: "122px",
            height: "35px"
        }
    },
    main: {
        borderRadius: "20px",
        backgroundColor: "#FFFFFF",
        boxShadow: "0 0 60px 0 rgba(0,0,0,0.06)",
        [theme.breakpoints.down("sm")]: {
            margin: "20px auto",
            padding: "20px 16px",
            height: "454px",
            width: "92%"
        },
        [theme.breakpoints.up("lg")]: {
            margin: "-220px auto 0",
            padding: "47px 40px 44px 40px",
            height: "708px",
            width: "600px"
        },
        fontFamily: "Inter-Regular",
        fontSize: "16px",
    },
    title: {
        fontFamily: "Stag-Medium",
        fontSize: "24px",
        fontWeight: "500",
        letterSpacing: "0",
        lineHeight: "28px",
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            marginBottom: "0px",
            color: "#003DA5",
            fontSize: "21px"
        },
        [theme.breakpoints.up("lg")]: {
            marginBottom: "57px",
            color: "#003DA5"
        }
    },
    message: {
        color: "#0A1F44",
        fontFamily: "Inter",
        letterSpacing: "0",
        textAlign: "center",
        lineHeight: "24px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "12px",
            lineHeight: "normal",
            "&:last-child": {
                marginTop: "4px"
            }
        },
        [theme.breakpoints.up("lg")]: {
            fontSize: "18px",
            "&:last-child": {
                marginTop: "37px"
            }
        }
    },
    image: {
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            "& svg": {
                width: "260px"
            }
        },

    },
    outerTitle: {
        [theme.breakpoints.down("sm")]: {
            margin: "20px auto",
            padding: "20px 16px",
            height: "454px",
            width: "92%"
        },
        [theme.breakpoints.up("lg")]: {
            margin: "0 auto",
            width: "500px",
            textTransform: "capitalize"
        },
        position: "relative",
        top: "-257px",
        left: "-30px",
        fontFamily: "Stag-Medium",
        fontSize: "24px"
    },
    languageSelect: {
        cursor: "pointer"
    }
}));

const RequirementsDiv = styled.div`
    & .firstLine {
        margin-bottom: 17px;
    }

    ul {
        padding-left: 22px;
    }
    
    li {
        padding-top: 8px;
    }
`;

const StyledList = styled.li`
    padding-left: 7px;
    margin-left: -7px; 
    
    ${({passed, cond2Status}) => {
    if (passed) {
        return css`
                    list-style-type:  '✓'; 
                    color: #009AE4;
                `
    } else {
        if (cond2Status) {
            return css`
                    list-style-type:  disc;   
                    opacity: 30%;
                    margin-left: 2px;
                `
        } else {
            return css`
                    list-style-type:  '✖';   
                    color: #D54040;
                `
        }
        
    }
}}
`;

const StyledTextFieldWrapper = styled.div`
    width: 100%;
    margin-top: 70px;
    .MuiInputLabel-formControl {
        top: -35px;
        color: #0a1f44;
        font-family: Inter;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 25px;
    }
    .MuiInputLabel-filled,
        .MuiInputLabel-filled .MuiInputLabel-shrink {
        transform: none !important;
    }
    .MuiFilledInput-underline:before,
    .form-field-container .MuiFormControl-root .MuiFilledInput-underline::after,
    .form-field-container .MuiFilledInput-underline:hover:before {
        border: none;
    }
    .MuiFormControl-root .MuiInputBase-root {
        background-color: transparent !important;
    }
    .MuiFormLabel-root.Mui-focused {
        color: #0a1f44 !important;
    }
    & .MuiInputLabel-formControl{
        ${lgDown`
            font-size: 16px;
            line-height: 24px;
            top: -30px;
        `}
    }
`;

const StyledQnectButton = styled(QnectButton)`
    width: 350px;
    line-height: 28px;
    font-size: 20px;
    font-weight: 500;
    font-family: Inter;
    background-color: #1d4fbb;
    text-transform: capitalize;
`;

const StyledButtonDiv = styled.div`
    text-align: center;
`;

const StyledModalDiv2 = styled(StyledModalDiv)`
    padding-top: 32.2px;
    width: 600px;
    height: 257px;
    .title {
        margin-bottom: 40px;
    }
    
    div.content {
        margin-bottom: 40px;
    }
    
    div.buttonDiv {
        margin: 0 auto;
        
        button {
            width: 180px;
        }
    }

`;

const ResetPasswordPage = (props) => {

    const classes = useStyles();
    const lang = useSelector(state => _getLang(state));
    const cfg = useConfig(lang);

    const [cond1, setCond1] = useState(false);
    const [cond2, setCond2] = useState(false);
    const [cond3, setCond3] = useState(false);
    const [cond4, setCond4] = useState(false);
    const [cond5, setCond5] = useState(false);
    const [cond6, setCond6] = useState(false);
    const [cond7, setCond7] = useState(false); // if password contains username

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
    const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState('');
    const params = new URLSearchParams(props.history.location.search);
    const [email, setEmail] = useState()
    const [country, setCountry] = useState();

    const [isShowPassword, setIsShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setIsShowPassword(!isShowPassword);
    };

    const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
    const handleClickShowConfirmPassword = () => {
        setIsShowConfirmPassword(!isShowConfirmPassword);
    };

    const [passwordMinLength, setPasswordMinLength] = useState();

    useEffect(() => {
        if (password.length >= passwordMinLength) {
            setCond1(true);
        } else {
            setCond1(false);
        }

        let usernameWithoutDomain = email ? email.split("@")[0] : email;
        if (!_.isEmpty(usernameWithoutDomain)) {
            if(password.toLowerCase().includes(usernameWithoutDomain.toLowerCase()) || isCommonPassword(password)) {
                setCond7(false);
            } else {
                setCond7(true);
            }
        }

        let cond2Checker = 0;

        if (password.match(/[a-z]/g)){
            cond2Checker++;
            setCond3(true);
        } else {
            setCond3(false);
        }

        if (password.match(/[A-Z]/g)){
            cond2Checker++;
            setCond4(true);
        } else {
            setCond4(false);
        }

        if (password.match(/\d/g)){
            cond2Checker++;
            setCond5(true);
        } else {
            setCond5(false);
        }

        if (password.match(/[^A-Za-z0-9]/g)){
            cond2Checker++;
            setCond6(true);
        } else {
            setCond6(false);
        }

        if (cond2Checker > 2) {
            setCond2(true);
        } else {
            setCond2(false);
        }

    }, [password, confirmPassword]);

    useEffect(() => {
        let token = params.get('token');
        let iv = params.get('iv');
        let cpToken = token + " " + iv;
        props.setCpToken(cpToken);
        TagManager.dataLayer({
            dataLayer: {
                event: 'vpageview',
                pageStep: 'Reset Password',
                vPath: '/resetPassword'
            }
        });

        props.showSpinner();
        props.decryptResetPasswordToken(token, iv).then((response) => {
            setEmail(response.email);
            setCountry(response.country);
            setPasswordMinLength(response.passwordMinLength);
            props.hideSpinner();
        }, () => {
            props.hideSpinner();
        });
    }, []);

    const handleResetPasswordSubmit = () => {
        if (password != confirmPassword) {
            setPasswordErrorMessage(cfg('registration.errorMessages.passwordNotMatch'));
            setConfirmPasswordErrorMessage(cfg('registration.errorMessages.passwordNotMatch'));
            return;
        }

        props.showSpinner();
        props.resetPassword(email, password).then((resp) => {
            if (resp === 'SUCCESS') {
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'popup',
                        popupPath: '/resetPassword',
                        popupTitle: 'Forgot Password'
                    }
                });
                props.showModal({
                    flat: true,
                    content: <StyledModalDiv2>
                        <div className="title"
                             style={props.lang === 'zh' ? {fontWeight: "bold"} : {}}>
                            {cfg('forgotPassword.resetPassword')}
                        </div>
                        <div className="content">{cfg('forgotPassword.resetPwMessage')}</div>
                        <div className="buttonDiv">
                            <StyledQnectButton
                                onClick={() => {
                                    props.hideModal();
                                    props.history.push('/login');
                                }}
                                field="close"
                            ></StyledQnectButton>
                        </div>
                    </StyledModalDiv2>,
                    disableBackDropClick: true
                })
            } else if (resp === 'FAILED') {
                setPasswordErrorMessage(cfg('resetPassword.errorMessages.notLast8'));
                setConfirmPasswordErrorMessage(cfg('resetPassword.errorMessages.notLast8'));
            }
            props.hideSpinner();
        }, (error) => {
            if (error.response.status === 500 || error.response.status === 400 || error.response.status === 404) {
                setPasswordErrorMessage(cfg('errorMessage.serverError'));
                setConfirmPasswordErrorMessage(cfg("errorMessage.serverError"));
            } else {
                setPasswordErrorMessage(error.response.statusText);
                setConfirmPasswordErrorMessage(error.response.statusText);
            }
            props.hideSpinner();
        });
    }

    const switchLanguage = () => {
        let newLang = props.lang === 'en' ? 'zh' : 'en';
        props.setLang(newLang);
    }

    const hideLanguage = () => {
        if(props.country === 'SGP' || props.country === 'MYS'){
            return true;
        }else{
            return false;
        }
    }

    const isCommonPassword = (password) => {
        var isCommonPass = true;
        var result=zxcvbn(password);

        if(result.score>=2){
            isCommonPass = false;
        }
        return isCommonPass;
    }

    return (
        <div>

            <div className={classes.wrapper}>
                <div className={classes.topBg}>
                    <div className={classes.topRow}>
                          { country === 'MYS' ?
                                <a href="https://www.qbe.com/my/about-qbe">
                                    <img src={QbeLogo} alt="QBE" className={classes.logo}/>
                                </a> :
                                <img src={QbeLogo} className={classes.logo} alt="QBE"/>

                          }
                        <div className={classes.languageSelect}
                             onClick={() => {switchLanguage()}} style={{ visibility: (hideLanguage ? 'hidden' : 'visible')}}>
                            {props.lang === "en" ? cfg("labels.lang_zh") : cfg("labels.lang_en")}
                        </div>
                    </div>
                </div>
                <div className={classes.outerTitle} style={props.lang === 'zh' ? {fontWeight: "bold"} : {}}>
                    {cfg("forgotPassword.resetPassword")}
                </div>
                <div className={classes.main}>
                    <RequirementsDiv>
                        <div className="firstLine">{cfg("forgotPassword.last8PassUsed")}</div>
                        <ul>
                            <StyledList passed={cond1}>{cfg("forgotPassword.minLength") + passwordMinLength + cfg("forgotPassword.minLength2")}</StyledList>
                            <StyledList passed={cond7}>{cfg("forgotPassword.notCommon")}</StyledList>
                            <StyledList passed={cond2}>{cfg("forgotPassword.haveAtleast3CharTypes")}</StyledList>
                            <ul>
                                <StyledList passed={cond3} cond2Status={cond2}>{cfg("forgotPassword.aLowerCase")}</StyledList>
                                <StyledList passed={cond4} cond2Status={cond2}>{cfg("forgotPassword.anUpperCase")}</StyledList>
                                <StyledList passed={cond5} cond2Status={cond2}>{cfg("forgotPassword.aNumeric")}</StyledList>
                                <StyledList passed={cond6} cond2Status={cond2}>{cfg("forgotPassword.aSpecial")}</StyledList>
                            </ul>

                        </ul>
                    </RequirementsDiv>
                    <StyledTextFieldWrapper>
                        <QnectTextField
                            field="resetPasswordNewPW"
                            type={isShowPassword ? 'text' : 'password'}
                            errorMessage={passwordErrorMessage}
                            onChange={(evt) => {
                                setPassword(evt.target.value);
                                setPasswordErrorMessage('');
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                    >
                                      {isShowPassword ? <HideIcon/> : <ShowIcon/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </StyledTextFieldWrapper>
                    <StyledTextFieldWrapper>
                        <QnectTextField
                            field="resetPasswordVerifyNewPW"
                            type={isShowConfirmPassword ? 'text' : 'password'}
                            errorMessage={confirmPasswordErrorMessage}
                            onChange={(evt) => {
                                setConfirmPassword(evt.target.value);
                                setConfirmPasswordErrorMessage('');
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowConfirmPassword}
                                    >
                                      {isShowConfirmPassword ? <HideIcon/> : <ShowIcon/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </StyledTextFieldWrapper>
                    <StyledButtonDiv style={{marginTop: (passwordErrorMessage || confirmPasswordErrorMessage ? '30px' : '50px')}}>
                        <StyledQnectButton
                            field="resetPassword"
                            onClick={handleResetPasswordSubmit}
                            disabled={ !cond1 || !cond2 || !cond7 }

                        />
                    </StyledButtonDiv>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        lang: _getLang(state)
    }
}

const mapDispatchToProps = {
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    decryptResetPasswordToken: _decryptResetPasswordToken,
    resetPassword: _resetPassword,
    showModal: _showModal,
    hideModal: _hideModal,
    setLang: _setLang,
    setCpToken: _setCpToken
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);